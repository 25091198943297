import { Injectable } from '@angular/core';
import { Profession } from '../main/employees/domain/profession';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { shareReplay, switchMap, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfessionService {
  private professionsRefresh = new BehaviorSubject<void>(undefined);
  private professionsRefresh$ = this.professionsRefresh.asObservable();

  readonly currentProfessions$ = this.professionsRefresh$.pipe(
    switchMap(() => this.httpClient.get<Profession[]>(`${environment.api}/profession`)),
    shareReplay(1),
  );

  readonly professions$ = this.currentProfessions$.pipe(
    map((professions) => professions.filter((profession) => !profession.isRemoved)),
  );

  constructor(private httpClient: HttpClient) { }

  refreshProfessions(): void {
    this.professionsRefresh.next();
  }

  addProfession(newProfession: { name: string }): Observable<{ msg: string }> {
    return this.httpClient.post<{ msg: string }>(`${environment.api}/admin/employee/profession`, newProfession)
      .pipe(
        tap(() => this.refreshProfessions()),
      );
  }

  editProfession(professionId: number, editedProfession: { name: string }): Observable<void> {
    return this.httpClient.put<void>(`${environment.api}/admin/employee/profession/${professionId}`, editedProfession)
      .pipe(
        tap(() => this.refreshProfessions()),
      );
  }

  removeProfession(professionId: number): Observable<void> {
    return this.httpClient.delete<void>(`${environment.api}/admin/employee/profession/${professionId}`)
      .pipe(
        tap(() => this.refreshProfessions()),
      );
  }
}
